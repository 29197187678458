exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-forms-index-js": () => import("./../../../src/pages/forms/index.js" /* webpackChunkName: "component---src-pages-forms-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-assay-lab-index-js": () => import("./../../../src/pages/services/assay-lab/index.js" /* webpackChunkName: "component---src-pages-services-assay-lab-index-js" */),
  "component---src-pages-services-geological-services-index-js": () => import("./../../../src/pages/services/geological-services/index.js" /* webpackChunkName: "component---src-pages-services-geological-services-index-js" */)
}

